import React, { useRef } from 'react'
import './OurServicesWeb.css'
import { Link } from 'react-router-dom'
import { useScroll, useTransform } from 'framer-motion'
import { motion
    
 } from 'framer-motion'
const scrollToTop = () => {
    window.scrollTo(0, 0)
}

const OurServices2 = ({ id }) => {
    const ref = useRef(null)
    const {scrollYProgress} = useScroll ({
      target:ref,
      offset:["0 1", "1.33 1"],
    })
  
    const y = useTransform(scrollYProgress,[0,1],[0.8, 1])
    const x = useTransform(scrollYProgress,[0,1],[0.6, 1])
  return (
    <motion.section ref={ref} style={{ scale:y,opacity:x,}} className='px-16 lg:px-40 py-6 ' id={id}>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2'>
            <div className="">
                <p className='text-[#013379] text-xl sm:text-[26px] font-bold leading-7'>WEB DESIGN</p>
                <p className='mt-2 leading-7 text-base'>Unsere Webdesign-Dienstleistungen umfassen:</p>
                <ul className='leading-7 list-disc px-4 mt-1'>
                    <li>Entwicklung von ansprechenden, funktionalen und benutzerfreundlichen Websites zur Förderung von Engagement, Conversions und Geschäftswachstum.</li>
                    <li>Entwicklung von Webanwendungen.</li>
                    <li>Frontend-Webentwicklung.</li>
                    <li>Backend-Webentwicklung.</li>
                    <li>Integration von Content-Management-Systemen (CMS).</li>
                    <li>Suchmaschinenoptimierung (SEO) Integration.</li>
                    <li>Benutzerdefinierte Webentwicklung.</li>
                    <li>Umgestaltung von Websites und mobiles Website-Design.</li>
                </ul>
                <div className='mt-6'>
                    <Link  onClick={scrollToTop}  to='/webdesign'><button type="submit" className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded">Einzelheiten</button></Link>
                </div>
            </div>
            <div className=' overlap-2 mt-8 l           g:px-12 lg:mr-12 xl:px-1'>
            <img className="wd1 floating " src="./web.jpg" alt="" />
            {/* <img className='wd2' src="./web1.jpg" alt="" /> */}
            </div>
        </div>
    </motion.section>
  )
}

export default OurServices2
