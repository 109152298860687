import React from 'react'
import { motion } from 'framer-motion';
const fadeRightVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };
const FreelancerDetails = () => {
  return (
    <motion.section
    variants={fadeRightVariants}
    initial="initial"
    animate="animate">
        <div className='px-16 lg:px-40'>
            <p className='text-[#013379] text-2xl sm:text-[26px] font-bold mt-2 py-2 leading-7'>FREIBERUFLER</p>
        </div>
        <div className='text-sm lg:text-base px-8 md:px-20 lg:px-40 py-2'>
            <p className='leading-6'>Um den Wert von Digihub Solutions UG für Unternehmen in Baden-Württemberg (BW) zu kommunizieren, die erstklassige freiberufliche Talente suchen, ist es wichtig, eine prägnante und ansprechende Botschaft zu entwickeln, die die Dienstleistungen hervorhebt und auf die Bedürfnisse und Herausforderungen der Zielgruppe eingeht. Hier ist ein strukturierter Ansatz zur Präsentation der Angebote:</p>
            <p className='mt-2'>Über Digihub Solutions UG</p>
            <p className='mt-2 font-semibold text-gray-800'>Wer wir sind:</p>
            <p className='mt-1 leading-6'>Digihub Solutions UG ist eine führende Personalvermittlungsagentur mit Sitz in Radolfzell, BW. Wir spezialisieren uns darauf, die Kluft zwischen fortschrittlichen Unternehmen und Spitzenfreiberuflern zu überbrücken. Wir fokussieren auf den Aufbau langfristiger Partnerschaften, die sowohl für unsere Klienten als auch für unsere Freelancer zum Erfolg führen.</p>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Unsere Kernleistungen</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>1. Spezialisiertes Freelancer-Sourcing:</p>
            <ul className='leading-7 ml-8 lg:ml-0 lg:px-12 list-disc mt-1'>
                <li>Wir bieten Zugang zu einem umfangreichen Netzwerk talentierter Freelancer, um die perfekte Übereinstimmung für Ihre Projektanforderungen zu finden.</li>
                <li>Unser Ansatz ist maßgeschneidert, wir verstehen die einzigartigen Fähigkeiten, die für den Erfolg Ihres Projekts entscheidend sind.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>2. Maßgeschneiderter Rekrutierungsprozess:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Unser Rekrutierungsprozess ist fein abgestimmt auf Ihre Betriebskultur und technische Anforderungen, um eine reibungslose Integration zu gewährleisten.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>3. Hochwertiges Kandidatenscreening:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Wir implementieren strenge Überprüfungsprozesse, um sicherzustellen, dass nur die qualifiziertesten und zuverlässigsten Freelancer vorgestellt werden.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>4. Laufende Freelancer-Unterstützung:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Wir bieten durchgehende Unterstützung und Begleitung während des Freelancer-Einsatzes, um die Erreichung der Projektziele zu gewährleisten.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Unsere Mission</p>
            <p className='mt-1 leading-7'>Wir sind bestrebt, qualitativ hochwertige Talentlösungen bereitzustellen, die zu erfolgreichen und beständigen Partnerschaften führen. Wir nehmen uns die Zeit, die Feinheiten jeder Anfrage zu verstehen und bieten personalisierte Dienstleistungen, die über einfache Fähigkeitsabgleiche hinausgehen.</p>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Warum Digihub Solutions UG?</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-800'>Lokale Expertise:</span> Wir verstehen die Geschäftslandschaft in BW und den Talentmarkt tiefgehend.</li>
                <li><span className='font-semibold text-gray-800'>Personalisierung:</span> Wir bevorzugen individuelle Lösungen gegenüber Einheitslösungen.</li>
                <li><span className='font-semibold text-gray-800'>Qualitätssicherung:</span> Wir verpflichten uns, durch sorgfältige Auswahlprozesse nur die besten Freelancer zu vermitteln.</li>
                <li><span className='font-semibold text-gray-800'>Unterstützende Zusammenarbeit:</span> Wir fördern produktive und positive Arbeitsbeziehungen durch fortlaufende Unterstützung.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Zielgruppe</p>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Unsere Dienstleistungen richten sich an:</p>
            <ul className='leading-7 list-disc ml-8  lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-800'>Startups bis hin zu etablierten Unternehmen:</span> Wir bieten die Flexibilität undExpertise, die Sie benötigen, um Ihr Team zu verstärken oder ein neues Projekt zu starten.</li>
                <li><span className='font-semibold text-gray-800'>Projektmanager:</span> Wir finden die spezialisierten Talente, die Sie für den Erfolg Ihres Projekts brauchen.</li>
                <li><span className='font-semibold text-gray-800'>Personalabteilungen:</span> Optimieren Sie Ihren Rekrutierungsprozess und verkürzen Sie die Zeit bis zur Einstellung.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Aufruf zum Handeln</p>
            <ul className='leading-7 list-disc ml-8  lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-800'>Für Unternehmen:</span> Kontaktieren Sie Digihub Solutions UG, um zu erfahren, wie unsere personalisierten Rekrutierungsdienste Ihre Projekte mit den besten Talenten ausstatten können.</li>
                <li><span className='font-semibold text-gray-800'>Für Freelancer:</span> Schließen Sie sich unserem Netzwerk an und nutzen Sie die Chance, mit führenden Unternehmen in BW zusammenzuarbeiten.</li>
            </ul>
            <p className='mt-2 leading-6 mb-4'>Indem wir diese strukturierte Botschaft nutzen, verdeutlichen wir den Nutzen von Digihub Solutions UG und wie wir als vertrauenswürdiger Partner die Personalanforderungen unserer Kunden erfüllen und gleichzeitig wertvolle Möglichkeiten für Freelancer schaffen.</p>
        </div>
    </motion.section>
  )
}

export default FreelancerDetails
