import React, { useRef } from 'react'
import './OurServicesCrm.css'
import { Link } from 'react-router-dom'
import {fadeIn} from "../variants"
import { motion, useScroll, useTransform } from 'framer-motion'

const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  
const OurServicesCrm = () => {
  const ref = useRef(null)
  const {scrollYProgress} = useScroll ({
    target:ref,
    offset:["0 1", "1.33 1"],
  })

  const y = useTransform(scrollYProgress,[0,1],[0.8, 1])
  const x = useTransform(scrollYProgress,[0,1],[0.6, 1])

  return (
    <motion.section ref={ref} style={{ scale:y,opacity:x,}} className='px-16 lg:px-40 py-4 bg-blue-50' id='Crm'>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2'>
        <div className='overlap-5 mt-2 sm:mt-10 sm:right-32 lg:right-64 lg:px-12 xl:px-6 xl:right-40 sm:ml-20'>
          <img className="floating crm1" src="./crm.jpg" alt="" />
          {/* <img className='crm2' src="./crm2.jpg" alt="" /> */}
        </div>
        <div className="mt-10 sm:mt-0 lg:pl-12">
            <p className='text-[#013379] text-xl sm:text-[26px] font-bold leading-9'>CRM</p>
            <p className='mt-2 leading-6 text-base'>Digihub Solutions UG bietet umfassende CRM-Unterstützung und Dienstleistungen, um Unternehmen bei der Auswahl, Implementierung, Nutzung und Optimierung von CRM-Systemen zu unterstützen.</p>
            <ul className='leading-7 list-disc px-4 mt-1'>
                <li>CRM-Bewertung und -Auswahl</li>
                <li>CRM-Implementierung</li>
                <li>CRM-Schulung</li>
                <li>CRM-Unterstützung</li>
                <li>CRM-Verbesserung</li>
                <li>CRM-Analyse und Reporting</li>
                <li>CRM-Datenmanagement und -sicherheit</li>
            </ul>
            <div className='mt-6'>
                <Link   onClick={scrollToTop}  to='/crm'><button className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded">Einzelheiten</button></Link>
            </div>
        </div>
        </div>
    </motion.section>
  )
}

export default OurServicesCrm
