import React, { useRef } from 'react'
import './OurServicesSales.css'
import { Link } from 'react-router-dom'
import { motion, useScroll, useTransform } from 'framer-motion'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const OurServicesSales = () => {
  const ref = useRef(null)
  const {scrollYProgress} = useScroll ({
    target:ref,
    offset:["0 1", "1.33 1"],
  })

  const y = useTransform(scrollYProgress,[0,1],[0.8, 1])
  const x = useTransform(scrollYProgress,[0,1],[0.6, 1])
  return (
    <motion.section ref={ref} style={{ scale:y,opacity:x,}} className='px-16 lg:px-40 py-4 mb-4' id='Sales'>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2'>
        <div className="">
            <p className='text-[#013379] text-xl sm:text-[26px] font-bold leading-9'>VERKAUF UND SERVICE VON COMPUTERN</p>
            <p className='mt-2 leading-7 text-base'>unsere Computer-Verkaufs- und Dienstleistungsangebote umfassen:</p>
            <ul className='leading-7 list-disc px-4 mt-1'>
                <li>Verkauf von Computern</li>
                <li>Computerzubehör</li>
                <li>Installation und Konfiguration von Software</li>
                <li>Computer-Upgrades und Reparaturen</li>
                <li>Entfernung von Viren und Malware</li>
                <li>Datensicherung und Wiederherstellung</li>
                <li>Netzwerkdienste</li>
                <li>Benutzerdefinierte Computer-Builds</li>
                <li>Fernunterstützung</li>
                <li>Schulung und Beratung</li>
            </ul>
            <p className='mt-2 leading-6 text-base'>Diese umfassenden Dienstleistungen bieten Privatpersonen und Unternehmen Unterstützung in allen Bereichen des Computerverkaufs, der Wartung und Optimierung.</p>
            <div className='mt-6'>
                <Link  onClick={scrollToTop}  to='/sales'><button type="submit" className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded">Einzelheiten</button></Link>
            </div>
        </div>
        <div className='overlap-4 mt-12 lg:px-14 lg:mr-12 xl:px-1'>
          <img className="floating" src="./sales.jpg" alt="" />
          {/* <img className='cs2' src="./sales2.jpg" alt="" /> */}
        </div>
        </div>
    </motion.section>
  )
}

export default OurServicesSales
