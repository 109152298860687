import React,{useRef} from 'react'
import './OurServicesIT.css'
import { Link } from 'react-router-dom'
import { motion, useScroll, useTransform } from 'framer-motion'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const OurServices = ({ id }) => {
  const ref = useRef(null)
  const {scrollYProgress} = useScroll ({
    target:ref,
    offset:["0 1", "1.33 1"],
  })

  const y = useTransform(scrollYProgress,[0,1],[0.8, 1])
  const x = useTransform(scrollYProgress,[0,1],[0.6, 1])

  return (
    <motion.div ref={ref} style={{ scale:y,opacity:x,}}>
    <section id={id} className='px-16 lg:px-40 py-4 overflow-hidden'>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2' >
        <div className="">
            <p className='text-[#013379] text-xl sm:text-[26px] font-bold mt-4 leading-7'>IT-ANWENDUNG UND -ENTWICKLUNG</p>
            <p className='mt-2 leading-6 text-base'>Verbesserung und Modernisierung von Anwendungen durch native und cloudbasierte Architekturlösungen.</p>
            <ul className='leading-7 list-disc px-4 mt-1'>
                <li>Entwicklung von Anwendungen auf Microservices basierender Architektur.</li>
                <li>Web- und mobilbasierte Anwendungsentwicklung.</li>
                <li>Modernisierung veralteter Anwendungen.</li>
                <li>Cloudbasierte Migration und Lösungen.</li>
                <li>CI/CD- und DevOps-Lösungen.</li>
                <li>Testautomatisierungslösungen.</li>
                <li>Datenanalyselösungen.</li>
            </ul>
            <div className='mt-6'>
                <Link   onClick={scrollToTop}  to='/itappdev'><button className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded">Einzelheiten</button></Link>
            </div>
        </div>
        <div className='overlap-1 mt-4 sm:mt-8  lg:px-12 lg:mr-12 xl:px-1'>
          <img className="floating" src="./itapp.jpg" alt="" />
          {/* <img className='image2' src="./app2.jpg" alt="" /> */}
        </div>
        </div>
    </section>
    </motion.div>
  )
};

export default OurServices
