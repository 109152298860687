import React from 'react'
import { Link } from 'react-router-dom'
import AboutUs from '../AboutUs/AboutUs'
import OurServices from '../OurServicesIT/OurServices'
import OurServices2 from '../OurServicesWeb/OurServices2'
import OurServicesDesign from '../OurServicesDesign/OurServicesDesign'
import OurServicesSales from '../OurServicesSales/OurServicesSales'
import OurServicesDigital from '../OurServicesOnline/OurServicesDigital'
import Freelancer from '../Freelancer/Freelancer'
import GetStarted from '../GetStarted/GetStarted'
// import Plans from '../Plans/Plans'
import ContactUs from '../ContactUs/ContactUs'
import OurServicesCrm from '../OurServicesCrm/OurServicesCrm'
import Whatsapp from '../Whatsapp/Whatsapp'
import { motion } from 'framer-motion'

const textVariants = {
    initial: {
        x:-500,
        opacity:0,
    },
    animate: {
        x:0,
        opacity:1,
        transition: {
            duration:1,
            staggerChildren:0.1,
        },
    },
};

const Home = () => {

    const scrollToOurServices = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth',
            });
        }
    };

  return (
    <div >
      <section className="">
        <Whatsapp />
        <div variants={textVariants} initial="initial" animate="animate" className="bg-[url('/public/bg-home.png')] w-full px-2 sm:px-12 bg-no-repeat bg-cover bg-center bg-fixed">
        <motion.div variants={textVariants} initial="initial" animate="animate">
            <motion.div variants={textVariants} initial="initial" animate="animate" className='text-[#013379] text-2xl sm:text-[30px] leading-7 sm:leading-none font-bold  py-4'>
                <motion.p variants={textVariants} className='px-12 lg:px-24  mt-8 sm:mb-2'>"Die Entwicklung von IT- und Web-Apps in Verbindung mit digitalem </motion.p>
                <motion.p variants={textVariants} className='px-12 lg:px-24'>Marketing treibt das Geschäftswachstum voran."</motion.p>
            </motion.div>
            <motion.p variants={textVariants} className='px-12 lg:px-24  text-sm sm:text-base'>Die Entwicklung von IT- und Webanwendungen gepaart mit digitaler Marketingunterstützung spielt eine entscheidende </motion.p>
            <motion.p variants={textVariants} className='px-12 lg:px-24  text-sm sm:text-base'>Rolle bei der Förderung Wachstum von Unternehmen im heutigen digitalen Zeitalter. Dieser integrierte Ansatz nutzt Technologie,</motion.p>
            <motion.p variants={textVariants} className='px-12 lg:px-24  text-sm sm:text-base'>um die Effizienz zu steigern und ein Ziel zu erreichen ein breiteres Publikum zu erreichen und einen Wettbewerbsvorteil zu schaffen.</motion.p>
            <motion.div variants={textVariants} className='px-24 mt-4'>
                <Link  to="/contactus"><button type="submit" className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded"><a href="#ContactUs">Lass uns reden</a></button></Link>
            </motion.div>

            <div className="max-w-screen-lg py-14 px-12 lg:px-24">
                <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-2 mb-4">
                    <div className="rounded-lg bg-white hover:shadow-gray-400 text-surface rounded overflow-hidden shadow-lg">
                    <div className="p-6">
                        <h5 className="mb-2 text-lg font-bold leading-tight text-[#013379]">IT-Anwendung und -Entwicklung</h5>
                        <motion.p variants={textVariants} className="mb-4 text-sm ">
                        Verbesserung und Modernisierung von Anwendungen durch native und cloudbasierte Architekturlösungen.
                        </motion.p>
                    </div>
                    <div className='px-6 mt-6 mb-6'>
                        <button  onClick={() => scrollToOurServices('ourServices')} className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded">Mehr wissen...</button>
                    </div>
                </div>
                <div className="rounded-lg bg-white hover:shadow-gray-400 text-surface rounded overflow-hidden shadow-lg">
                    <div className="p-6">
                        <h5 className="mb-1 text-lg font-bold leading-tight text-[#013379]">Websitedesign</h5>
                        <motion.p variants={textVariants} className="text-sm">
                        Wir bieten Dienstleistungen an, die die Erstellung, Verbesserung und Pflege von Websites umfassen – eine wesentliche Komponente beim Aufbau einer starken Online-Präsenz.
                        </motion.p>
                    </div>
                    <div className='px-6 mt-2 mb-6'>
                        <button onClick={() => scrollToOurServices('ourServices2')} type="submit" className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded">Mehr wissen...</button>
                    </div>
                </div>
                <div className="rounded-lg bg-white text-surface  hover:shadow-gray-400 rounded overflow-hidden shadow-lg">
                    <div className="p-6">
                        <h5 className="mb-2 text-lg font-bold leading-tight text-[#013379]">Online Marketing</h5>
                        <motion.p variants={textVariants} className="mb-4 text-sm">
                        Verbessern Sie Ihr Markenimage mit professionellen Logos, Broschüren, Visitenkarten, Speisekarten, Flyern, Postern und mehr.
                        </motion.p>
                    </div>
                    <div className='px-6 mt-2 mb-6'>
                        <button  onClick={() => scrollToOurServices('ourServicesDigital')} type="submit" className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded">Mehr wissen...</button>
                    </div>
                </div>
                </div>
            </div>
            </motion.div>
        </div>
      </section>
      <AboutUs id="aboutUs"/>
      <OurServices id="ourServices"/>
      <OurServicesCrm />
      <OurServices2 id="ourServices2"/>
      <OurServicesDesign />
      <OurServicesDigital id="ourServicesDigital" />
      <Freelancer />
      <OurServicesSales />
      <GetStarted />
      {/* <Plans /> */}
      <ContactUs />
    </div>
  )
}

export default Home
