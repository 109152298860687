import React, {useEffect, useRef} from 'react'
import { motion, useInView } from 'framer-motion'

const fadeRightVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

const AboutUs = ({ id }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: false, amount: 0.5 });

  return (
        <motion.section variants={fadeRightVariants} initial="initial" ref={ref} animate={isInView && "animate"} className='px-16 lg:px-40 py-4 bg-blue-50' id={id}>
        <motion.div  variants={fadeRightVariants}>
            <h2 className="text-lg text-[#013379] font-bold">über uns</h2>
            <p className='mt-4 py-2 leading-7 text-base'>Wir sind ein IT-Entwicklungsberatungs- und Dienstleistungsunternehmen mit besonderem Fokus auf Online-Marketing, Grafikdesign, Computerverkauf und -dienstleistungen sowie Website-Entwicklung in Radolfzell, Baden-Württemberg. Unsere Kernwerte liegen in innovativen Lösungen und herausragendem Kundenservice. Wir bieten freiberufliche Personallösungen und unterstützen Unternehmen aus allen Branchen.</p>
            <p className='mt-4 leading-7 text-base'>Durch unsere Expertise in der Entwicklung und Betreuung von IT-Anwendungen helfen wir Unternehmen, Prozesse zu optimieren und ihre digitale Präsenz zu stärken. Unser professionellesTeam strebt danach, innovative Lösungen zu liefern, die messbare Ergebnisse erzielen und unserenKunden dabei helfen, ihre Ziele zu erreichen.</p>
        </motion.div>
        <motion.div  variants={fadeRightVariants} className='flex lg:flex-row flex-col mt-6'>
        <div  variants={fadeRightVariants} className='mt-4'>
            <h2 className="text-lg text-[#013379] font-bold">Unsere Mission</h2>
            <p className='mt-4 py-2 leading-7 text-base'>Die Mission Unseres Unternehmens besteht darin, Kunden durch die Entwicklung innovativer und kostengünstiger Lösungen zu bedienen und den Weg für eine Win-Win-Situation zu ebnen. Dieser Ansatz zielt darauf ab, sowohl für das Unternehmen als auch für die Kunden vorteilhafte Ergebnisse zu erzielen.</p>
        </div>
        <div  variants={fadeRightVariants} className='mt-4 lg:ml-4'>
            <h2 className="text-lg text-[#013379] font-bold">Unsere Vision</h2>
            <p className='mt-4 py-2 leading-7 text-base'>Unsere Vision beinhaltet das Ziel, eines der Top 5 Unternehmen für exzellenten Service zu sein, mit Fokus auf Mitarbeiterfreundlichkeit, Innovation in Dienstleistungen und Produkten sowie den 3Cs: Zusammenarbeit, Engagement, Veränderung und Kommunikation. Das sind bedeutende Säulen für den Erfolg und die Weiterentwicklung Ihres Unternehmens.</p>
            <ul className='leading-7 list-disc px-4'>
                <li>Collaboration</li>
                <li>Commitment</li>
                <li>Change and Communication</li>
            </ul>
        </div>
        </motion.div>
        </motion.section>
  )
}

export default AboutUs
