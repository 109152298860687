import { HashRouter } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer/Footer';
import ScrollToTop from 'react-scroll-to-top'
import { ReactComponent as MySVG } from "./arrow.svg";
import Whatsapp from './components/Whatsapp/Whatsapp';
import NavSection from './components/NavSection/NavSection';
import ParticlesComponent from './components/Particle/Particle';
import AnimatedRoutes from './components/AnimatedRoutes';
import { useEffect } from "react";

function App() {
  return (
    <div className='bg-white-400 '>
      <ParticlesComponent id="particles" />
      <HashRouter>
      <NavSection />
      <ScrollToTop smooth top='100' component={<MySVG />}/>
      <Whatsapp />
      <AnimatedRoutes />
      </HashRouter>
      <Footer />
    </div>
  );
}

export default App;
