import React ,{Fragment, useRef, useState} from 'react'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import FooterModal from './FooterModal';
import './FooterModal.jsx'
import { motion,useInView } from 'framer-motion';

const fadeDownVariants = {
  initial: {
    y: -50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
};
const Footer = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.5 });
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  // const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
}
const scrollToOurServices = (id) => {
  const element = document.getElementById(id);
  if (element) {
      window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
      });
  }
};


  return (
    <section ref={ref} className='px-4 lg:px-40 py-8 bg-blue-50'>
        <footer className="">
        <motion.div
        variants={fadeDownVariants}
        initial="initial"
        animate={isInView ? "animate" : "initial"} className="container  mx-4 mr-6 lg:mx-auto xl:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 md:64 lg:gap-80">
            <div className='text-gray-400 text-sm'>
              <img  onClick={scrollToTop}  className="h-10 w-auto mb-4" src="./logo.png"/>
              <p className="mb-4">Unleashing IT Innovation, Achieving Digital Excellence</p>
              <div className="flex items-center mb-4">
              <a href="https://www.facebook.com/profile.php?id=61556999285516" target="_blank" className="mr-4">
                <FaFacebook size="2em" />
              </a>
              <a href="https://www.instagram.com/digihubsolutions.de/" target="_blank" className="mr-4">
                <FaInstagram size="2em" />
              </a>
              <a href="" target="_blank" className="mr-4">
                <FaLinkedin size="2em" />
              </a>
              </div>
              <p>© 2024 DIGIHUB Solutions UG. Alle Rechte vorbehalten</p>
            </div>
            <div >
              <div className='md:text-end lg:text-end'>
              <h2 className="text-lg font-semibold mb-4 lg:text-end">DIGIHUB Solutions UG</h2>  
              <button  onClick={() => scrollToOurServices('aboutUs')}   className='mb-4 text-gray-400 lg:text-end'>Über uns</button>
              <p></p>
              <button onClick={() => setShowModal4(true)}><p className='mb-2 text-gray-400 text-end'>Karriere</p></button>
              <div className=" text-base md:text-end lg:text-end items-center text-blue-900">
                <button onClick={() => setShowModal(true)}>Impressum</button>
                <span className="mx-2">|</span>
                <button onClick={() => setShowModal2(true)}>Datenschutzerklärung</button>
              </div>
              </div>
              <Fragment>
              <FooterModal isVisible={showModal} children onClose={() => setShowModal(false)}>
                <div className='p-6'>
                  <h3 className='text-xl sm:text-2xl font-bold mb-5 text-center'>
                  Impressum
                  </h3>
                  <div className="legal-content overflow-y-auto max-h-96">
                  <p className='mb-5  text-base sm:text-xl'>Angaben gemäß Informationspflicht laut § 5 Telemediengesetz (TMG).</p>
                  <p className='mt-1 text-sm sm:text-base'>Digihub Solution UG</p>
                  <p className='mt-1 text-sm sm:text-base'>Rommel Str 2,</p>
                  <p className='mt-1 text-sm sm:text-base'>78315 Radolfzell,</p>
                  <p className='mt-1 text-sm sm:text-base'>Deutschland</p>
                  <p className='mt-1 text-sm sm:text-base'>Umsatzsteuer-Identifikationsnummer : DE 366713681</p>
                  <p className='mt-1 text-sm sm:text-base'>Register: Handelsregister</p>
                  <p className='mt-1 text-sm sm:text-base'>Registernummer: HRB730545</p>
                  <p className='mt-1 text-sm sm:text-base'>Registergericht: Freiburg</p>
                  <p className='mt-1 text-sm sm:text-base'>Tel.: +49 7732 60 11 368</p>
                  <p className='mt-1 text-sm sm:text-base'>Fax: +49 7732 60 11 369</p>
                  <p className='mt-1 text-sm sm:text-base'>E-Mail: info@digihubsolutions.de</p>
                  <p className='mt-1 text-sm sm:text-base'>Aufsichtsbehörde</p>
                  <p className='mt-1 text-sm sm:text-base'>Freiburg Regional Council</p>
                  <p className='mt-1 text-sm sm:text-base'>Geschäftsführer</p>
                  <p className='mt-1 text-sm sm:text-base'>Dr. Darma Vivekachandran &amp; Naga ShanmugaPriya Venkatasamy</p>
                  <p className='mt-4 text-sm sm:text-base'>Kontaktdaten des Verantwortlichen für Datenschutz</p>
                  <p className='mt-1 text-sm sm:text-base'>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:</p>
                  <p className='mt-1 text-sm sm:text-base'>Rommelstraße 2, 78315 Radolfzell</p>
                  <p className='mt-1 text-sm sm:text-base'>E-Mail-Adresse: info@digihubsoltions.de</p>
                  <p className='mt-1 text-sm sm:text-base'>Telefon: +49 7732 60 11 368</p>
                  <p className='mt-1 text-sm sm:text-base'>Impressum: https://www.digihubsoltuions.de/Impressum</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>EU-Streitschlichtung</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online Streitbeilegungsplattform (OS-Plattform) informieren.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;Ing=DE zu richten. Die dafür notwendigen</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Haftung für Inhalte dieser Website</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Website übernehmen, speziell für jene, die seitens</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht verpflichtet, die von Ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit davon</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>unberührt</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Haftung für Links auf dieser Website</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Unsere Website enthält Links zu anderen Websites für deren Inhalt wir nicht verantwortlich sind.Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeitenhatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Urheberrechtshinweis</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Bildernachweis</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Die Bilderrechte liegen bei:</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Digihub Solutions UG</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Alle Texte sind urheberrechtlich geschützt.</p>

                  </div>
                </div>
              </FooterModal>
              <FooterModal isVisible={showModal2} children onClose={() => setShowModal2(false)}>
                <div className='p-6'>
                <h2 className='text-xl sm:text-2xl font-bold mb-5 text-center '>Datenschutzerklärung</h2>
                <div className="legal-content overflow-y-auto max-h-96">
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Einleitung und Überblick</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Wir haben diese Datenschutzerklärung (Fassung 24.04.2024-322775009) verfasst, um Ihnen gemäß den Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche - und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) – verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Kurz gesagt: Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische Begriffe leserfreundlich erklärt, Links zu weiterführenden Informationen geboten und Grafiken zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Anwendungsbereich</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
                  <p className='leading-7 text-sm sm:text-base'>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben Social Media Auftritte und E-Mail-Kommunikation</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>mobile Apps für Smartphones und andere Geräte</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Kurz gesagt: Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir. Außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Rechtsgrundlagen</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz- Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.</p>
                  <p className='leading-7 text-sm sm:text-base'>Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz- Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU- Recht, unter https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679 nachlesen.</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>1. Einwilligung (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars. 2. Vertrag (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen,</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen. 3. Rechtliche Verpflichtung (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>4. Berechtigte Interessen (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>In Österreich ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (Datenschutzgesetz), kurz DSG.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>In Deutschland gilt das Bundesdatenschutzgesetz, kurz BDSG</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Kontaktdaten des Verantwortlichen</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Digihub Solutions UG</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Rommelstraße 2, 78315 Radolfzell</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>E-Mail: info@digihubsoltions.de</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Telefon: +49 7732.60 11 368</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Impressum: https://www.digihubsoltuions.de/Impressum</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Speicherdauer</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprünglichen Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>Rechte laut Datenschutz-Grundverordnung</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:</p>
                  <p className='mt-4 font-semibold text-sm sm:text-base'>zu welchem Zweck wir die Verarbeitung durchführen;</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>die Kategorien, also die Arten von Daten, die verarbeitet werden;</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann: wie lange die Daten gespeichert werden;</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten); die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sie haben laut Artikel 17 DSGVO das Recht auf Löschung (Recht auf Vergessenwerden&quot;), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiterverwenden.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Kurz gesagt: Sie haben Rechte - zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder lhre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter https://www.dsb.gv.at/ finden. In Deutschland gibt e für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig</p>
                  <p className='mt-4 leading-7 mb-8 text-sm sm:text-base'>Alle Texte sind urheberrechtlich geschützt.</p>
                </div>
                </div>
              </FooterModal>
              {/* <FooterModal isVisible={showModal3} children onClose={() => setShowModal3(false)}>
                <div className='p-6'>
                  <h3 className='text-xl sm:text-2xl font-bold mb-5 text-center'>Terms & Conditions</h3>
                  <div className="legal-content overflow-y-auto max-h-96">
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam inventore, distinctio at eos magnam dolorem deserunt quisquam itaque tempore.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam inventore, distinctio at eos magnam dolorem deserunt quisquam itaque tempore.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam inventore, distinctio at eos magnam dolorem deserunt quisquam itaque tempore.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam inventore, distinctio at eos magnam dolorem deserunt quisquam itaque tempore.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam inventore, distinctio at eos magnam dolorem deserunt quisquam itaque tempore.</p>
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam inventore, distinctio at eos magnam dolorem deserunt quisquam itaque tempore.</p>
                  </div>
                </div>
              </FooterModal> */}
              <FooterModal isVisible={showModal4} children onClose={() => setShowModal4(false)}>
                <div className='p-6'>
                  <h3 className='text-xl sm:text-2xl font-bold mb-5 text-center'>Karriere</h3>
                  <div className="legal-content overflow-y-auto max-h-96">
                  <p className='mt-4 leading-7 text-sm sm:text-base'>Bitte senden Sie Ihre Profile an info@digihubsolutions.de, unser Team wird sich bei Ihnen melden.</p>
                  </div>
                </div>
              </FooterModal>
              </Fragment>
            </div>
          </div>
        </motion.div>
      </footer>
    </section>
  )
}

export default Footer
