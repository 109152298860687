import React,{useEffect, useRef} from 'react'
import { inView, motion, useInView } from 'framer-motion'

const textVariants = {
    initial: {
        x:-500,
        opacity:0,
    },
    animate: {
        x:0,
        opacity:1,
        transition: {
            duration:1,
            staggerChildren:0.1,
        },
    },
};
const fadeUpVariants = {
    initial: {
      y: 100,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };
const fadeRightVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };
  const fadeLeftVariants = {
    initial: {
      x: 100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };
  const Div = ({ variants, children }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: false });
  
    return (
      <motion.div
        ref={ref}
        variants={variants}
        initial="initial"
        animate={isInView ? 'animate' : 'initial'}
        className="section-container"
      >
        {children}
      </motion.div>
    );
  };

const NursingDetails = () => {
  return (
    <section className='relative ' >
      {/* <div className="absolute inset-0 bg-[url('/public/nurse1bg.jpg')] w-full px-2 sm:px-12 bg-no-repeat bg-cover bg-center bg-fixed"></div> */}
      <div variants={textVariants} initial="initial" animate="animate" className="relative px-16 lg:px-40 py-4">
        <div className='text-[#013379] text-xl sm:text-[24px] leading-6 sm:leading-7 font-bold'>
            <Div variants={textVariants}>
            <p >"Verbessern Sie Ihre berufliche Laufbahn durch erstklassige Krankenpflegeausbildung und Zugang zu internationalen Rekrutierungsmöglichkeiten."</p>
            <p className='mt-8'>Digihub Solutions UG kann sich als Dienstleister positionieren, der sich auf die internationale Rekrutierung und Ausbildung von Krankenpflegekräften spezialisiert hat. Um diesen Service erfolgreich zu vermarkten, könnte das Unternehmen einen strukturierten Ansatz wählen, der die folgenden Elemente umfasst:</p>
            </Div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2'>
        <Div variants={fadeRightVariants} >
        <div className="mt-12">
            <p className='text-[#013379] text-xl sm:text-[22px] font-bold leading-7'>Dienstleistungsübersicht:</p>
            <p className='mt-2 leading-7 text-lg sm:text-xl font-semibold'>Internationale Rekrutierung:</p>
            <p className='mt-2'>Digihub Solutions UG bietet eine umfangreiche internationale Rekrutierung von qualifiziertem Krankenpflegepersonal für den deutschen Gesundheitsmarkt.</p>
            <p className='mt-2 leading-7 text-lg sm:text-xl font-semibold'>Ausbildung und Integration:</p>
            <p className='mt-2'>Ausbildung und Integration: Wir bieten nicht nur die Vermittlung von Fachkräften, sondern unterstützen auch deren Ausbildung, Spracherwerb und Integration in Deutschland.</p>
        </div></Div>
        <Div variants={fadeLeftVariants}>
          <img className="" src="./nurse1.png" alt="" />
        </Div>
        </div>
    </div>
    <Div variants={fadeUpVariants}>
        <div className='bg-blue-50 px-16 lg:px-40 py-4'>
            <p className='text-[#013379] text-2xl sm:text-[22px] font-semibold leading-7'>Schrittweise Darstellung des Angebots:</p>
            <p className='mt-2 text-xl font-semibold text-gray-700'>1. Analyse und Bedarfsfeststellung:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-700'>Bedarfsermittlung:</span> Erfassung der spezifischen Anforderungen der Einrichtungen.</li>
                <li><span className='font-semibold text-gray-700'>Marktanalyse:</span> Identifikation von Ländern mit einem Überschuss an ausgebildeten Pflegekräften.</li>
            </ul>
            <p className='mt-2 text-xl font-semibold text-gray-700'>2. Rekrutierungsprozess:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-700'>Stellenausschreibung:</span> Gezielte Ausschreibung von Stellen in ausgewählten Ländern.</li>
                <li><span className='font-semibold text-gray-700'>Vorauswahl:</span> Sichtung der Bewerbungen und Vorselektion geeigneter Kandidaten.</li>
                <li><span className='font-semibold text-gray-700'>Interviews:</span> Durchführung von Interviews in Kooperation mit den Auftraggebern, ggf. per Videokonferenz.</li>
            </ul>
            <p className='mt-2 text-xl font-semibold text-gray-700'>3. Qualifizierungsmaßnahmen:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-700'>Sprachkurse:</span> Organisation von Deutschkursen im Heimatland der Kandidaten oder in Deutschland.</li>
                <li><span className='font-semibold text-gray-700'>Fachliche Weiterbildung:</span> Anpassungsqualifikation zur Anerkennung der Berufsabschlüsse in Deutschland.</li>
            </ul>
            <p className='mt-2 text-xl font-semibold text-gray-700'>4. Visum- und Einreiseverfahren:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-700'>Visa-Beratung:</span> Unterstützung bei der Beantragung der erforderlichen Visa.</li>
                <li><span className='font-semibold text-gray-700'>Relocation-Services:</span> Hilfe bei der Wohnungssuche und bei behördlichen Anmeldungen in Deutschland.</li>
            </ul>
            <p className='mt-2 text-xl font-semibold text-gray-700'>5. Integration und Einarbeitung:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-700'>Integrationskurse:</span> Angebot von Kursen zur kulturellen und sozialen Integration.</li>
                <li><span className='font-semibold text-gray-700'>Einarbeitungsprogramme:</span> Entwicklung von individuellen Einarbeitungsplänen in Kooperation mit den Arbeitgebern.</li>
            </ul>
            <p className='mt-2 text-xl font-semibold text-gray-700'>6. Nachhaltige Betreuung:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-700'>Kontinuierliche Betreuung:</span> Regelmäßige Evaluierung der Zufriedenheit von Arbeitgebern und Arbeitnehmern.</li>
                <li><span className='font-semibold text-gray-700'>Fortbildung:</span> Planung und Durchführung von Fortbildungen zur kontinuierlichen Weiterentwicklung der Fachkräfte.</li>
            </ul>
            <p className='mt-2 text-xl font-semibold text-gray-700'>7. Zusatzleistungen:</p>
             <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-700'>Rechtsberatung:</span> Unterstützung bei arbeitsrechtlichen Fragen im Kontext internationaler Beschäftigungsverhältnisse.</li>
                <li><span className='font-semibold text-gray-700'>Netzwerkbildung:</span> Aufbau eines Netzwerks für internationales Pflegepersonal zur Förderung des Erfahrungsaustauschs.</li>
            </ul>
        </div></Div>
        <div className='px-16 lg:px-40 py-2'>
            <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2'>
                <Div variants={fadeRightVariants}>
                <div className='sm:px-16'>
                    <img className="sm:px-16" src="./nurse4.png" alt="" />
                </div></Div>
                <Div variants={fadeLeftVariants}>
                <div className="mt-4">
                    <p><span className='sm:ml-8 font-semibold text-gray-700'>Zielgruppe:</span></p>
                    <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-2'>
                        <li>Krankenhäuser, Kliniken und medizinische Versorgungszentren.</li>
                        <li>Pflege- und Altenheime.</li>
                        <li>Spezialisierte Einrichtungen wie Kinderkrankenhäuser, Augenkliniken und zahnmedizinische Zentren.</li>
                    </ul>
                    <p className='mt-4'><span className='font-semibold text-gray-700'>Kontakt und Beratung:</span> Interessierte Einrichtungen können sich über eine dedizierte Kontaktseite, per E-Mail oder Telefon an Digihub Solutions UG wenden, um eine persönliche Beratung zu vereinbaren.</p>
                    <p className='mt-4  mb-4'>Digihub Solutions UG sollte dieses Angebot angesichts des Fachkräftemangels im Pflegebereich und der zunehmenden Internationalisierung des Arbeitsmarktes kommunizieren. Dieser strukturierte Ansatz hilft potenziellen Kunden, die angebotenen Dienstleistungen zu verstehen und fördert das Vertrauen in die Kompetenz von Digihub Solutions UG in diesem spezialisierten Bereich.</p>
                </div></Div>
            </div>
        </div>
        <Div variants={fadeUpVariants}>
        <div className='bg-blue-50 px-16 lg:px-40 py-6'>
            <p className='text-[#013379] text-2xl sm:text-[22px] font-semibold leading-6'>Für den Rekrutierungsprozess in der Gesundheitsbranche in Deutschland könnten folgende Schritte relevant sein:</p>
            <ul className='list-decimal mt-2 px-4 leading-7'>
                <li><span className='font-semibold text-gray-700'>Bedarfsanalyse:</span> Ermitteln Sie den konkreten Bedarf an Fachkräften in der Gesundheitsbranche, basierend auf Fachgebieten, Standorten und Qualifikationen.</li>
                <li><span className='font-semibold text-gray-700'>Stellenprofil erstellen:</span> Definieren Sie klare Stellenprofile mit den erforderlichen Qualifikationen, Erfahrungen und Kompetenzen für die zu besetzenden Positionen.</li>
                <li><span className='font-semibold text-gray-700'>Personalbeschaffung:</span> Nutzen Sie verschiedene Kanäle wie Online-Stellenbörsen, soziale Netzwerke, Kooperationen mit Bildungseinrichtungen und Personalvermittlungen, um potenzielle Kandidaten zu erreichen.</li>
                <li><span className='font-semibold text-gray-700'>Bewerbungseingang und -auswahl:</span> Sichten Sie Bewerbungen, führen Sie Vorstellungsgespräche und wählen Sie geeignete Kandidaten aus, die den Anforderungen entsprechen.</li>
                <li><span className='font-semibold text-gray-700'>Vertragsverhandlungen und -abschluss:</span> Klären Sie Vertragsdetails, Gehaltsvereinbarungen und weitere Bedingungen mit den ausgewählten Kandidaten.</li>
                <li><span className='font-semibold text-gray-700'>Onboarding-Programm:</span> Entwickeln Sie ein strukturiertes Einarbeitungsprogramm, um neuen Mitarbeitern einen optimalen Start in ihre neuen Positionen zu ermöglichen.</li>
                <li><span className='font-semibold text-gray-700'>Fortbildung und Entwicklung:</span> Bieten Sie kontinuierliche Fortbildungs- und Entwicklungsmöglichkeiten an, um das Fachwissen und die Fähigkeiten der Mitarbeiter zu erweitern.</li>
                <li><span className='font-semibold text-gray-700'>Feedback und Leistungsüberwachung:</span> Implementieren Sie regelmäßige Feedback-Mechanismen und Leistungsüberwachungen, um die Zufriedenheit der Mitarbeiter zu gewährleisten und Leistungsverbesserungen zu fördern.</li>
            </ul>
            <p className='mt-4 leading-6'>Durch die Umsetzung eines strukturierten Rekrutierungsprozesses können Unternehmen in der Gesundheitsbranche in Deutschland qualifizierte Fachkräfte gewinnen, halten und langfristig binden, um den steigenden Anforderungen des Gesundheitswesens gerecht zu werden.</p>
            <p className='mt-4'>Digihub Solutions ist eine umfassende Ressource für die Rekrutierung und Schulung von Pflegekräften. Unser erfahrenes Team steht Ihnen gerne bei der Suche nach hochqualifizierten Kandidaten für Ihre Pflegeabteilung zur Seite.</p>
            <p className=''>Kontaktieren Sie uns unter <a href='mailto:Pflege@digihubsolutions.de'>Pflege@digihubsolutions.de</a>, um herauszufinden, wie wir Ihnen bei Ihrem Personalbeschaffungsbedarf helfen können. Wir sind bestrebt, die beste Lösung für Ihre Organisation zu finden und die Qualität Ihres Pflegeteams zu verbessern.</p>
        </div></Div>
        <Div variants={fadeRightVariants}>
        <div className='px-16 lg:px-40 py-2 mb-4'>
            <div className="mt-4">
                <p className='text-[#013379] text-2xl sm:text-[22px] font-semibold leading-7'>Rekrutierungsprogramm für FSJ-Kandidaten</p>
                <p className='mt-2 leading-6'>Digihub Solutions Radolfzell widmet sich der Förderung des FSJ-Programms, indem es motivierte junge Menschen mit sinnvollen Möglichkeiten in Sozial-, Gesundheits- und Umweltorganisationen in ganz Deutschland verbindet. Unser Ziel ist es, die persönliche und berufliche Entwicklung der Teilnehmer zu unterstützen und gleichzeitig einen Beitrag zur Gemeinschaft zu leisten.</p>
                <p className='mt-2 text-xl font-semibold text-gray-700'>Zielgruppe:</p>
                <p>Junge Menschen im Alter zwischen 16 und 26 Jahren, die Erfahrungen im sozialen Bereich sammeln möchten.</p>
                <p>Organisationen im Sozial-, Gesundheits- und Umweltsektor suchen engagierte Zeitarbeitskräfte.</p>
            </div>
        </div></Div>
    </section>
  )
}

export default NursingDetails
