import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const fadeRightVariants = {
  initial: {
    x: -50,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
};

const GetStarted = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.5 });

  return (
    <section ref={ref}>
      <motion.div
        variants={fadeRightVariants}
        initial="initial"
        animate={isInView ? "animate" : "initial"}
        className="bg-gradient-to-r from-[#059669] via-[#2bdca7] to-[#059669] px-16 sm:px-20 py-16 sm:py-24"
      >
        <motion.h1
          variants={fadeRightVariants}
          className='text-[24px] sm:text-[30px] text-white text-center font-bold'
        >
          LASSEN SIE UNS IHR UNTERNEHMEN AUF DIE NÄCHSTE STUFE HEBEN
        </motion.h1>
        <div className='items-center justify-center text-center mt-12'>
          <Link to='/contactus' onClick={scrollToTop}>
            <a type="button" href="#ContactUs" className="text-white hover:text-white border border-white-700 hover:bg-[#059669] focus:ring-4 focus:outline-none focus:ring-white-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-white-500 dark:text-white-500 dark:hover:text-white dark:hover:bg-white-500 dark:focus:ring-white-800">
              Loslegen
            </a>
          </Link>
        </div>
      </motion.div>
    </section>
  );
};

export default GetStarted;
