import React from 'react'
import { motion } from 'framer-motion';
const fadeRightVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };
const CrmDetails = () => {
  return (
    <motion.section
    variants={fadeRightVariants}
    initial="initial"
    animate="animate">
        <div className='px-16 lg:px-40'>
            <p className='text-[#013379] text-2xl sm:text-[26px] font-bold mt-2 py-2 leading-7'>CRM (KUNDENBEZIEHUNGSMANAGEMENT)</p>
        </div>
        <div className='text-sm lg:text-base px-8 md:px-20 lg:px-40 py-2'>
            <p className='leading-6'>Digihub Solutions UG bietet umfassende CRM-Unterstützung und Dienstleistungen, um Unternehmen bei der Auswahl, Implementierung, Nutzung und Optimierung von CRM-Systemen zu unterstützen. Hier ein detaillierter Überblick über die angebotenen Services:</p>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>CRM-Bewertung und - Auswahl</p>
            <p className='mt-1'><span className='font-semibold text-gray-800'>Ziel:</span> Unternehmen bei der Auswahl des passenden CRM-Systems unterstützen.</p>
            <p className='mt-1 font-semibold text-gray-800'>Überlegungen:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Bedarfsanalyse: Verstehen der spezifischen Anforderungen des Unternehmens an ein CRM-System.</li>
                <li>Marktanalyse: Information über aktuelle CRM-Lösungen und Abgleich mit den Bedürfnissen des Kunden.</li>
                <li>Anpassung vs. Standardlösung: Entscheidungshilfe, ob eine maßgeschneiderte oder eine Standardlösung besser geeignet ist.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>CRM-Implementierung</p>
            <p className='mt-1'><span className='font-semibold text-gray-800'>Ziel:</span> Effiziente Einrichtung und Anpassung des CRM-Systems für das Unternehmen.</p>
            <p className='mt-1 font-semibold text-gray-800'>Überlegungen:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Datenmigration: Unterstützung beim Transfer von Daten aus alten Systemen in das neue CRM.</li>
                <li>Anpassung: Anpassung des CRM an die individuellen Prozesse des Unternehmens.</li>
                <li>Integration: Verknüpfung des CRM mit anderen Geschäftssystemen und Tools.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>CRM-Schulung</p>
            <p className='mt-1'><span className='font-semibold text-gray-800'>Ziel:</span> Effektive Schulung des Teams des Unternehmens im Umgang mit dem CRM-System.</p>
            <p className='mt-1 font-semibold text-gray-800'>Überlegungen:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Benutzerschulung: Entwicklung und Durchführung von Trainingsprogrammen.</li>
                <li>Supportmaterial: Erstellung von Hilfsmaterialien wie Handbücher und Tutorials.</li>
                <li>Feedback-Schleife: Etablierung eines Feedbacksystems für kontinuierliche Verbesserungen.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>CRM-Unterstützung</p>
            <p className='mt-1'><span className='font-semibold text-gray-800'>Ziel:</span> Laufende Unterstützung bei allen Anliegen bezüglich des CRM-Systems.</p>
            <p className='mt-1 font-semibold text-gray-800'>Überlegungen:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Helpdesk-Dienste: Angebot verschiedener Supportoptionen.</li>
                <li>Wartung: Regelmäßige Überprüfung des CRM-Systems auf Aktualisierungen und Optimierungen.</li>
                <li>Fehlerbehebung: Schnelle Lösung technischer Probleme.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>CRM-Verbesserung</p>
            <p className='mt-1'><span className='font-semibold text-gray-800'>Ziel:</span> Kontinuierliche Aktualisierung des CRM-Systems entsprechend den sich ändernden Geschäftsanforderungen.</p>
            <p className='mt-1 font-semibold text-gray-800'>Überlegungen:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Funktionsaktualisierungen: Einführung neuer Funktionen und Features.</li>
                <li>Benutzerdefinierte Entwicklung: Erstellung spezifischer Funktionen oder Integrationen.</li>
                <li>Leistungsüberprüfung: Regelmäßige Bewertung der CRM-Leistung und Vorschläge für Verbesserungen.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>CRM-Analyse und Reporting</p>
            <p className='mt-1'><span className='font-semibold text-gray-800'>Ziel:</span> Unternehmen helfen, CRM-Daten für fundierte geschäftliche Entscheidungen zu nutzen.</p>
            <p className='mt-1 font-semibold text-gray-800'>Überlegungen:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Berichtstools: Einrichtung und Anpassung von Reporting-Tools.</li>
                <li>Datenanalyse: Analyse der CRM-Daten zur Bereitstellung von Erkenntnissen.</li>
                <li>Verkaufsprognosen: Unterstützung bei der Erstellung von Verkaufsprognosen.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>CRM-Datenmanagement und -sicherheit</p>
            <p className='mt-1'><span className='font-semibold text-gray-800'>Ziel:</span> Sicherstellung der Datenintegrität und -sicherheit.</p>
            <p className='mt-1 font-semibold text-gray-800'>Überlegungen:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Datenrichtlinien: Entwicklung von Standards zur Aufrechterhaltung der Datenqualität.</li>
                <li>Sicherheitsmaßnahmen: Implementierung von Sicherheitsvorkehrungen zum Schutz von Kundendaten.</li>
                <li>Compliance: Sicherstellung der Einhaltung von Datenschutzbestimmungen wie der DSGVO.</li>
            </ul>
            <p className='mt-2 leading-6 mb-4'>Durch die Bereitstellung dieser strukturierten CRM-Support-Services kann Digihub Solutions UG Unternehmen dabei unterstützen, das Potenzial ihrer CRM-Systeme voll auszuschöpfen, die Kundenbeziehungen zu verbessern und den Vertriebserfolg zu steigern.</p>
        </div>
    </motion.section>
  )
}

export default CrmDetails
