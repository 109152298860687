import React from 'react'
import { motion } from 'framer-motion';
const fadeRightVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };

const SalesDetails = () => {
  return (
    <motion.section
    variants={fadeRightVariants}
    initial="initial"
    animate="animate" >
        <div className='px-16 lg:px-40'>
            <p className='text-[#013379] text-2xl sm:text-[26px] font-bold mt-2 py-2 leading-7'>VERKAUF UND SERVICE VON COMPUTERN</p>
        </div>
        <div className='text-sm lg:text-base px-8 md:px-20 lg:px-40 py-2'>
            <p className='leading-6'>Die strukturierte Präsentation der Computerverkaufs- und Serviceleistungen von Digihub Solutions UG bietet potenziellen Kunden einen klaren und umfassenden Einblick in das Angebot des Unternehmens:</p>
            <p className='mt-1 leading-7'>Einführung in Digihub Solutions UG - Computerverkauf und Service</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>Unser Engagement:</p>
            <p className='mt-1 leading-7'>Ziel, führender Anbieter von Computer-Verkaufs- und Servicelösungen zu sein und Kunden bei allen Computeranforderungen zu unterstützen.</p>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Unsere Computer-Verkaufsdienstleistungen</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>1. Große Produktpalette:</p>
            <ul className='leading-7 ml-8 lg:ml-0 lg:px-12 list-disc mt-1'>
                <li>Bereitstellung aktueller Modelle führender Marken für persönliche und geschäftliche Anwendungen.</li>
                <li>Vielfalt an Optionen, einschließlich Desktops, Laptops, Workstations und Tablets.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>2. Expertenberatung:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Unterstützung bei der Auswahl passender Geräte basierend auf Kundenbedürfnissen und Budget.</li>
                <li>Bereitstellung detaillierter Informationen zu Spezifikationen und Funktionen.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>3. Wettbewerbsfähige Preise:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Angebot wettbewerbsfähiger Preise mit personalisiertem Kundenservice.</li>
                <li>Kommunikation von Angeboten, Rabatten und Garantien.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Unsere Computer-Service-Lösungen</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>1. Technischer Support:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Bereitstellung von Support vor Ort und remote für verschiedene Computerprobleme.</li>
                <li>Fernunterstützung für schnelle Lösungen.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>2. Wartungsdienste:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Geplante Wartung für optimale Computerleistung.</li>
                <li>Reinigung, Software-Updates und Hardware-Checks.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>3. Reparaturdienste:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Umfassende Diagnose und Reparatur von Hardware- und Softwareproblemen.</li>
                <li>Expertise in der Reparatur verschiedener Marken und Modelle.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>4. Upgrade-Services:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Hardware- und Software-Upgrades für verbesserte Leistung und Aktualität.</li>
                <li>Bereitstellung von Datenwiederherstellung und -sicherungsdiensten.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>Unser Erfahrungsschatz</p>
            <p className='mt-1 leading-7'>Zertifizierte Techniker, kundenzentrierter Ansatz und schnelle, zuverlässige Dienstleistungen.</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>Warum sollten Sie sich für Digihub-Lösungen entscheiden?</p>
            <p className='mt-1 leading-7'>Umfassende Betreuung, Anpassung an individuelle Anforderungen und langfristige Kundenbeziehungen.</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>Ideale Kunden</p>
            <p className='mt-1 leading-7'>Heimanwender, Geschäftskunden und Bildungseinrichtungen.</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>Aufruf zum Handeln</p>
            <p className='mt-1 leading-7'>Einladung an potenzielle Kunden, um Unterstützung bei ihren Computeranforderungen zu erhalten.</p>            
            <p className='mt-2 leading-6 mb-4'>Diese strukturierte Präsentation hebt die Stärken und das Serviceangebot von Digihub Solutions  UG im Bereich Computerverkauf und -service hervor und unterstreicht das Engagement für erstklassige Dienstleistungen und Kundenzufriedenheit.</p>            
        </div>
    </motion.section>
  )
}

export default SalesDetails
