import React from 'react'
import { motion } from 'framer-motion';
const fadeRightVariants = {
    initial: {
      x: -200,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };
const Gallery = () => {
  return (
    <motion.div variants={fadeRightVariants}
    initial="initial"
    animate="animate" className='px-4 lg:px-40 py-8'>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
            <div className="grid gap-8">
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./001.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./006.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./009.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./007.jpg" alt="" />
                </div>
            </div>
            <div className="grid gap-8">
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./002.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./008.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./004.jpg" alt="" />
                </div>
            </div>
            <div className="grid gap-8">
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./010.png" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./005.jpg" alt="" />
                </div>
                <div>
                    <img className="h-auto max-w-full rounded-lg" src="./003.jpg" alt="" />
                </div>
            </div>
        </div>

    </motion.div>
  )
}

export default Gallery
