import React from 'react'
import { motion } from 'framer-motion';
const fadeRightVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };
const WebDesignDetails = () => {
  return (
    <motion.section
    variants={fadeRightVariants}
    initial="initial"
    animate="animate" >
        <div className='px-16 lg:px-40'>
            <p className='text-[#013379] text-2xl sm:text-[26px] font-bold mt-2 py-2 leading-7'>WEB DESIGN</p>
        </div>
        <div className='text-sm lg:text-base px-8 md:px-20 lg:px-40 py-2'>
            <p className='leading-6'>Die strukturierte Aufschlüsselung der Dienstleistungen von Digihub Solutions UG verdeutlicht das Fachwissen und die Vorteile des Unternehmens im Bereich Webdesign und -entwicklung für kleine und mittlere Unternehmen (KMU). Durch die Hervorhebung des Wertversprechens und der angebotenen Lösungen wird das einzigartige Angebot von Digihub Solutions UG prägnant präsentiert:</p>
            <p className='mt-2 font-semibold text-gray-800'>Einführung in Digihub Solutions UG:</p>
            <p className='mt-1 leading-7'>Beschreibung der Mission und Vision des Unternehmens, das darauf abzielt, KMU mit professionellen Webdesign- und Entwicklungsdiensten zu unterstützen.</p>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Unsere Dienstleistungen umfassen:</p>
            <p className='mt-1 text-lg lg:text-xl font-semibold text-gray-800'>1. Individuelles Webdesign:</p>
            <ul className='leading-7 ml-8 lg:ml-0 lg:px-12 list-disc mt-1'>
                <li>Maßgeschneiderte Ästhetik, die Ihre Markenidentität und Werte reflektiert.</li>
                <li>Fokussierung auf Benutzererfahrung (UX) zur Steigerung der Besucherbindung.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>2. Webentwicklung:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Entwicklung robuster und agiler Weblösungen auf Basis neuester Technologien.</li>
                <li>Skalierbare Architektur, die mit den Geschäftsanforderungen wächst.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>3. E-Commerce-Lösungen:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Schaffung intuitiver Einkaufserlebnisse mit optimierten Checkout- Prozessen.</li>
                <li>Integration sicherer Zahlungsgateways und Bestandsverwaltungssysteme.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>4. Responsives Design:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Erstellung von Mobile-First-Websites mit nahtloser Darstellung und Funktionalität auf allen Geräten.</li>
                <li>Adaptive Layouts für Zugänglichkeit und Benutzerfreundlichkeit unterwegs.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>5. Suchmaschinenoptimierung (SEO):</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Optimierung von Inhalten und Struktur für verbesserte Suchmaschinen- Rankings.</li>
                <li>Implementierung von Strategien zur Steigerung des organischen Traffics und zur Erhöhung der Online-Sichtbarkeit.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>6. Wartung und Support:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Kontinuierliche Website-Pflege zur Optimierung der Leistung.</li>
                <li>Zuverlässiger Support für alle nachträglichen Probleme oder Updates nach dem Start.</li>
            </ul>
            <p className='mt-2 leading-6'>Unser Erfahrungsschatz liegt in der Bereitstellung dieser Dienstleistungen durch ein erfahrenes Team, das sich durch Fachkenntnisse, Kreativität und Engagement auszeichnet. Wir garantieren eine professionelle Abwicklung jedes Projekts mit Liebe zum Detail und höchster Qualität.</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>Warum sollten Sie sich für Digihub Solutions UG entscheiden?</p>
            <p className='mt-1 leading-7'>Darlegung der Qualität, des Wettbewerbsvorteils und der umfassenden Strategie, die für Kunden von Nutzen sind.</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>Ideale Kunden:</p>
            <p className='mt-1 leading-7'>Zielgruppenbeschreibung, einschließlich kleiner und mittelständischer Unternehmen, die von den Dienstleistungen von Digihub Solutions UG profitieren können.</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>Wertversprechen:</p>
            <p className='mt-1 leading-7'>Betonung von Partnerschaft, skalierbaren Lösungen, benutzerdefinierten Funktionen und budgetfreundlichen Optionen als Schlüsselfaktoren des Wertversprechens.</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>Fazit und Aufruf zum Handeln:</p>
            <p className='mt-1 leading-7'>Abschließende Worte zur Bedeutung von Vertrauen, Einladung zur Kontaktaufnahme für eine Zusammenarbeit und klare Kontaktdaten für potenzielle Kunden.</p>            
            <p className='mt-2 leading-6 mb-4'>Diese strukturierte Präsentation hebt die Stärken und den Mehrwert von Digihub Solutions UG für KMU hervor und unterstreicht die Bereitschaft des Unternehmens, maßgeschneiderte Lösungen für den Online-Erfolg seiner Kunden anzubieten.</p>            
        </div>
    </motion.section>
  )
}

export default WebDesignDetails
