import React from 'react'
import { HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import ContactUs from './ContactUs/ContactUs';
import DesignDetails from './DesignDetails/DesignDetails';
import DigitalDetails from './DigitalDetails/DigitalDetails';
import ItAppDetails from './ItAppDetails/ItAppDetails';
import CrmDetails from './CrmDetails/CrmDetails';
import SalesDetails from './SalesDetails/SalesDetails';
import WebDesignDetails from './WebDesignDetails/WebDesignDetails';
import Plans from './Plans/Plans'; 
import FreelancerDetails from './FreelancerDetails/FreelancerDetails';
import {AnimatePresence} from 'framer-motion'
import Home from './Home/Home';
import AboutUs from './AboutUs/AboutUs';
import NursingDetails from './NursingDetails/NursingDetails';
import Gallery from './Gallery/Gallery';


const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <div>
      <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={<Home />}></Route>
        <Route path='/aboutus' element={<AboutUs />}/>
        <Route path='/nursing' element={<NursingDetails />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/plans' element={<Plans />}/>
        <Route path='/itappdev' element={<ItAppDetails />} />
        <Route path='/crm' element={<CrmDetails />} />
        <Route path='/webdesign' element={<WebDesignDetails />}/>
        <Route path='/graphicdesign' element={<DesignDetails />}/>
        <Route path='/online' element={<DigitalDetails />}/>
        <Route path='/freelancer' element={<FreelancerDetails />}/>
        <Route path='/sales' element={<SalesDetails />}/>
        <Route path='/gallery' element={<Gallery />}/>
      </Routes>
      </AnimatePresence>
    </div>
  )
}

export default AnimatedRoutes
