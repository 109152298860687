import React from 'react'
import { motion } from 'framer-motion';
const fadeRightVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };
const DesignDetails = () => {
  return (
    <motion.section
    variants={fadeRightVariants}
    initial="initial"
    animate="animate">
        <div className='px-16 lg:px-40'>
            <p className='text-[#013379] text-2xl sm:text-[26px] font-bold mt-2 py-2 leading-7'>GRAFIKDESIGN</p>
        </div>
        <div className='text-sm lg:text-base px-8 md:px-20 lg:px-40 py-2'>
            <p className=''>Einführung in die Grafikdesign-Services von Digihub Solutions UG</p>
            <p className='mt-2 font-semibold text-gray-800'>Wer wir sind:</p>
            <p className='mt-1 leading-6'>Digihub Solutions UG ist ein Kreativzentrum, das sich auf Grafikdesign- Dienstleistungen spezialisiert hat, um Unternehmen bei der wirkungsvollen Kommunikation ihrer Markenbotschaft zu unterstützen. Unser Team widmet sich der Erstellung visueller Inhalte, die die Zielgruppen ansprechen und die Markenbekanntheit stärken.</p>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Unsere Grafikdesign-Dienstleistungen</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>1. Markenidentitätsdesign:</p>
            <ul className='leading-7 ml-8 lg:ml-0 lg:px-12 list-disc mt-1'>
                <li>Logo, Farbschemata und Typografie für eine starke Markenidentität.</li>
                <li>Umfassende Marken-Styleguides für Konsistenz über alle Medien.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>2. Marketing- und Werbematerial:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Gestaltung von Broschüren, Flyern, Postern und Bannern zur Kernbotschaftsvermittlung.</li>
                <li>Werbematerialien für digitalen und gedruckten Vertrieb.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>3. Digitales Design:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Webdesign-Elemente zur Verbesserung des Benutzererlebnisses und der Ästhetik.</li>
                <li>Sozial-Media-Grafiken für die gezielte Ansprache auf verschiedenen Plattformen.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>4. Verpackungsdesign:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Innovative Verpackungskonzepte für Regalpräsenz und Markenethos.</li>
                <li>Funktionales Design für Ästhetik und Benutzererfahrung.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>5. Redaktionelles Design:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Layout und Typografie für Zeitschriften, Bücher, Berichte.</li>
                <li>Optische Attraktivität und Lesbarkeit für hochwertige Druckmaterialien.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>6. Infografiken und Datenvisualisierung:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Komplexe Daten in leicht verdauliche visuelle Darstellungen umwandeln.</li>
                <li>Kreative Infografiken zur Information und Einbindung des Publikums.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Warum sollten Sie sich für Digihub Solutions UG für Grafikdesign entscheiden?</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-800'>Maßgeschneiderte Kreativität:</span> Designs an die Marke anpassen.</li>
                <li><span className='font-semibold text-gray-800'>Erfahrene Designer:</span> Immer auf dem neuesten Stand der Trends und Technologien.</li>
                <li><span className='font-semibold text-gray-800'>Strategischer Ansatz:</span> Designs, die strategischen Geschäftszielen dienen.</li>
                <li><span className='font-semibold text-gray-800'>Kollaborativer Prozess:</span> Eng mit Kunden zusammenarbeiten für übertreffende Ergebnisse.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Ideale Kunden für unsere Grafikdesign-Dienstleistungen</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Unternehmen im Rebranding.</li>
                <li>Startups beim Markenaufbau.</li>
                <li>Organisationen, die Marketingmaterialien benötigen.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Aufruf zum Handeln</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-800'>Für verbesserte visuelle Wirkung:</span> Kontaktieren Sie Digihub Solutions UG für eine Steigerung Ihrer Markenpräsenz.</li>
                <li><span className='font-semibold text-gray-800'>Für Kreativprofis:</span> Treten Sie dem Team bei für spannende Projekte.</li>
            </ul>
            <p className='mt-2 leading-6 mb-4'>Durch die Darstellung der Grafikdesignleistungen von Digihub Solutions UG, deren potenzielle Auswirkungen und den Aufruf zum Handeln können potenzielle Kunden erkennen, wie diese Dienste ihre Marke und Kommunikationsstrategien verbessern können. Dieser Ansatz verdeutlicht Fachkenntnisse, Engagement und Kundenzufriedenheit.</p>
        </div>
    </motion.section>
  )
}

export default DesignDetails
