import React, { useRef, useState } from 'react'
import { motion, useInView } from 'framer-motion';

const fadeLeftVariants = {
  initial: {
    x: 50,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
};

const ContactUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.5 });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const validateForm = event => {
    event.preventDefault();
    if (!firstName || !lastName || !email || !phone || !message) {
      alert('Please fill out all fields.');
      return;
    }
    const firstNameValue = document.getElementById("first-name").value;
    const lastNameValue = document.getElementById("last-name").value;
    const emailValue = document.getElementById("email").value;
    const phoneValue = document.getElementById("phone").value;
    const messageValue = document.getElementById("message").value;
    const subject = `Contact Form Submission - ${firstNameValue} ${lastNameValue}`;
    const body = `First Name: ${firstNameValue}\nLast Name: ${lastNameValue}\nEmail: ${emailValue}\nPhone Number: ${phoneValue}\nMessage: ${messageValue}\n`;
    const mailtoLink = `mailto:info@digihubsolutions.de?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
    return false;
  };
  return (
    <section className='px-12 lg:px-40 ' id="ContactUs" ref={ref}>
        <div className="max-w-7xl mx-auto pt-8 pb-8 grid md:grid-cols-2 lg:grid-cols-2 gap-y-8 md:gap-x-8 md:gap-y-8 lg:gap-x-8 lg:gap-y-16">
        <div>
            <h2 className="text-[#013379] text-xl sm:text-[26px] font-bold">NEHMEN SIE KONTAKT MIT UNS AUF</h2>
            <p className="max-w-sm text-[18px] mt-4 mb-4">Für Rückfragen:</p>
            <div className="flex items-center mt-2 space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                    stroke="currentColor" aria-hidden="true" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75">
                    </path>
                </svg>
                <a  className="text-base" href="mailto:info@digihubsolutions.de">info@digihubsolutions.de</a>
            </div>
            <div className="flex items-center mt-2 space-x-2 ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                    stroke="currentColor" aria-hidden="true" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z">
                    </path>
                </svg>
                <a href="tel:+(49) 7732 60 11 368">+(49) 7732 60 11 368</a>
            </div>
            <div className="w-60 rounded-lg overflow-hidden mt-4">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5183.197615523285!2d8.936996!3d47.75411!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a623dd1b32b07%3A0x2aa6d29ebf302202!2sRommelstra%C3%9Fe%202%2C%2078315%20Radolfzell%20am%20Bodensee%2C%20Germany!5e1!3m2!1sen!2sin!4v1713524004312!5m2!1sen!2sin" width="250" height="365" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <motion.div
        variants={fadeLeftVariants}
        initial="initial"
        animate={isInView ? "animate" : "initial"} >
        <form className="mx-auto max-w-xl" onSubmit={validateForm} target='_blank' method='GET'>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div>
        <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-700">Vorname</label>
        <div className="mt-2.5">
          <input  type="text" value={firstName} onChange={e => setFirstName(e.target.value)} name="first-name" id="first-name" autoComplete="given-name" placeholder="Ihre Vorname" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" required />
        </div>
      </div>
      <div>
        <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-700">Nachname</label>
        <div className="mt-2.5">
          <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} name="last-name" id="last-name" autoComplete="family-name" placeholder="Ihre Nachname" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" required/>
        </div>
      </div>
      <div className="sm:col-span-2">
        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-700">Email</label>
        <div className="mt-2.5">
          <input  type="email" value={email} onChange={e => setEmail(e.target.value)} name="email" id="email" autoComplete="email" placeholder="Ihre E-Mail-Adresse" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" required/>
        </div>
      </div>
      <div className="sm:col-span-2">
        <label htmlFor="phone" className="block text-sm font-semibold leading-6 text-gray-700">Telefonnummer</label>
        <div className="mt-2.5">
          <input  type="tel" value={phone} onChange={e => setPhone(e.target.value)} name="phone" id="phone"  autoComplete="tel" placeholder="Ihre Telefonnummer" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" required/>
        </div>
      </div>
      <div className="sm:col-span-2">
        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-700">Nachricht</label>
        <div className="mt-2.5">
          <textarea value={message} onChange={e => setMessage(e.target.value)} name="message" id="message" rows="4" placeholder="Ihre Nachricht" className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" required></textarea>
        </div>
      </div>
    </div>
    <div className="mt-10">
      <button type="submit" className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white rounded-xl py-3 w-full block">Einreichen</button>
    </div>
  </form>
        </motion.div>
        </div>
    </section>
  )
}

export default ContactUs
