import React from 'react'

const Plans = () => {
  return (
    <section className='px-16 lg:px-40 py-12 bg-gray-50' id="Plans">
        <div className='flex lg:flex-row flex-col mt-8'>
            <div className='mt-4'>
                <h1 className='text-[#013379] text-4xl font-bold'>PLANS</h1>
            </div>
            <div className='mt-4 lg:ml-96 lg:w-3/4'>
                <p className='leading-7'>Our solutions are guidelines that we have put together based on our years of experience. However, we recognize that every service is unique and has individual requirements that do not fit into a rigid package.</p>
            </div>
        </div>

        <div>
        <div className="mt-24 container space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div className="relative bg-white p-8 border border-gray-200 rounded-2xl shadow-sm flex flex-col">
            <div className="flex-1">
                <h3 className="text-2xl font-semibold ">Startup</h3>
                <p className="mt-4 flex items-baseline ">For Starters</p>
            </div>
        </div>
        <div className="relative p-8  bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
            <div className="flex-1">
                <h3 className="text-2xl font-semibold ">Business</h3>
                <p className="absolute top-0 py-1.5 px-4 bg-emerald-500 text-white rounded-full text-xs font-semibold uppercase tracking-wide  transform -translate-y-1/2"> Most popular</p>
                <p className="mt-6 ">For a successful business start</p>
                </div>
        </div>
        <div className="relative p-8 bg-white  border border-gray-200 rounded-2xl shadow-sm flex flex-col">
            <div className="flex-1">
                <h3 className="text-2xl font-semibold ">Premium</h3>
                <p className="mt-6 ">Dedicated support and infrastructure for your company.</p>
            </div>
        </div>
    </div>
        </div>
    </section>
  )
}

export default Plans
