import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { motion } from 'framer-motion';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    // Close the other dropdown
    setShowDropdown2(false);
  };

  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2);
    // Close the other dropdown
    setShowDropdown(false);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };
  const closeDropdown2 = () => {
    setShowDropdown2(false);
  };

  const closeAllDropdowns = () => {
    setShowDropdown(false);
    setShowDropdown2(false);
  };

  return (
    <nav className="flex items-center justify-between flex-wrap p-4 ">
      <Link to='/'>
      <div className="flex items-center text-black pl-2 md:pl-8 lg:pl-28">
        <motion.img initial={{opacity:0,scale:0.5}}
          animate={{opacity:1,scale:1}} 
          transition={{duration:0.5}} className="w-9 h-6 lg:w-14 lg:h-10 mr-4" src="/logo.png" alt="Logo" />
        <div>
          <motion.h3 initial={{opacity:0,scale:0.5}}
          animate={{opacity:1,scale:1}} 
          transition={{duration:0.5}} className="sm:text-lg text-[18px]"><span className='sm:text-xl text-[18px] font-bold'>DIGIHUB</span> Solutions</motion.h3>
          <motion.h3 initial={{opacity:0,scale:0.5}}
          animate={{opacity:1,scale:1}} 
          transition={{duration:0.5}} className="text-gray-400 hidden lg:block text-xs">Unleashing IT Innovation, Achieving Digital Excellence</motion.h3>
        </div>
      </div></Link>

      <div className="block lg:hidden">
        <button onClick={toggleMenu} className="flex items-center pr-2 md:px-3 md:py-2 rounded text-black-500 hover:text-black-400">
          <svg
            className={`fill-current h-4 w-4 ${isOpen ? "hidden" : "block"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
          <svg
            className={`fill-current h-4 w-4 ${isOpen ? "block" : "hidden"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </button>
      </div>

      <div className={`w-full pr-12 lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`}>
        <motion.div initial={{opacity:0,scale:0.5}}
          animate={{opacity:1,scale:1}} 
          transition={{duration:0.5}} className="text-sm lg:flex-grow mt-2  ">
          <div className="pl-16 text-center lg:text-right"> 
            <Link to='/' onClick={closeAllDropdowns}><a href="#" className="block mt-4 lg:inline-block lg:mt-0 text-black-500 mr-4 hover:bg-[#1aa97e] hover:text-white px-3 py-2 rounded-md">Heim</a></Link>
            <Link to='/aboutus' onClick={closeAllDropdowns}><a href="#" className="block mt-4 lg:inline-block lg:mt-0 text-black-500 mr-4 hover:bg-[#1aa97e] hover:text-white px-3 py-2 rounded-md">Über uns</a></Link>
            <div className="relative inline-block text-left">
              <button onClick={toggleDropdown}  className="block mt-4 lg:inline-block lg:mt-0 text-black-500 mr-4 hover:bg-[#1aa97e] hover:text-white px-3 py-2 rounded-md">Unsere Dienstleistungen</button>
              {showDropdown && (
                <div className="absolute z-10 -ml-4 mt-2 w-60 bg-white rounded-lg shadow-lg">
                  <Link to='/itappdev' onClick={closeDropdown}><a href="#ITApp" className="block px-4 py-2 text-sm hover:bg-gray-100">IT-Anwendung und -Entwicklung</a></Link>
                  <Link to='/crm' onClick={closeDropdown}><a href="#CRM" className="block px-4 py-2 text-sm hover:bg-gray-100">CRM (Kundenbeziehungsmanagement)</a></Link>
                  <Link to='/webdesign' onClick={closeDropdown}><a href="#WebDesign" className="block px-4 py-2 text-sm hover:bg-gray-100">Websitedesign</a></Link>
                  <Link to='/graphicdesign' onClick={closeDropdown}><a href="#GraphicDesign" className="block px-4 py-2 text-sm hover:bg-gray-100">Grafikdesign</a></Link>
                  <Link to='/online' onClick={closeDropdown}><a href="#Online" className="block px-4 py-2 text-sm hover:bg-gray-100">Online Marketing</a></Link>
                  <Link to='freelancer' onClick={closeDropdown}><a href="#Freelancer" className="block px-4 py-2 text-sm hover:bg-gray-100">Freiberuflich</a></Link>
                  <Link to='/sales' onClick={closeDropdown}><a href="#Sales" className="block px-4 py-2 text-sm hover:bg-gray-100">Verkauf und Service von Computern</a></Link>
                </div>
              )}
            </div>
            {/* <div className="relative inline-block text-left">
              <button onClick={toggleDropdown2} className="block mt-4 lg:inline-block lg:mt-0 text-black-500 mr-4 hover:bg-[#1aa97e] hover:text-gray-800 px-3 py-2 rounded-md">Nursing</button>
              {showDropdown2 && (
                <div className="absolute z-10 -ml-4 mt-2 w-60 bg-white rounded-lg shadow-lg">
                  <Link to='/itappdev' onClick={closeDropdown2}><a href="#ITApp" className="block px-4 py-2 text-sm hover:bg-gray-100">IT-Anwendung und -Entwicklung</a></Link>
                  <Link to='/crm' onClick={closeDropdown2}><a href="#CRM" className="block px-4 py-2 text-sm hover:bg-gray-100">CRM (Kundenbeziehungsmanagement)</a></Link>
                </div>
              )}
            </div> */}
            <Link to='/nursing' onClick={closeAllDropdowns}><a href="#" onClick={closeDropdown} className="block mt-4 mb-4 lg:inline-block lg:mt-0 text-black-500 mr-4 hover:bg-[#1aa97e] hover:text-white px-3 py-2 rounded-md">Pflege</a></Link>
            <Link to='/gallery' onClick={closeAllDropdowns}><a href="#" onClick={closeDropdown} className="block mt-4 mb-4 lg:inline-block lg:mt-0 text-black-500 mr-4 hover:bg-[#1aa97e] hover:text-white px-3 py-2 rounded-md">Galerie</a></Link>
            <Link to='/contactus' onClick={closeAllDropdowns}><a href="#" onClick={closeDropdown} className="block mt-4 mb-4 lg:inline-block lg:mt-0 text-black-500 mr-4 hover:bg-[#1aa97e] hover:text-white px-3 py-2 rounded-md">Kontaktiere uns</a></Link>
            <div className="pt-4 "id="google_translate_element" ></div>
          </div>
        </motion.div>
      </div>
    </nav>
  );
};

export default Navbar;
