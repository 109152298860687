import React, { useRef } from 'react'
import './OurServicesDigital.css'
import { Link } from 'react-router-dom'
import {motion, useScroll, useTransform } from 'framer-motion'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const OurServicesDigital = ({ id }) => {
  const ref = useRef(null)
  const {scrollYProgress} = useScroll ({
    target:ref,
    offset:["0 1", "1.33 1"],
  })

  const y = useTransform(scrollYProgress,[0,1],[0.8, 1])
  const x = useTransform(scrollYProgress,[0,1],[0.6, 1])
  return (
    <motion.section id={id} ref={ref} style={{ scale:y,opacity:x,}} className='px-16 lg:px-40 '>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2'>
            <div className="mt-6">
            <p className='text-[#013379] text-xl sm:text-[26px] font-bold leading-7'>ONLINE MARKETING</p>
            <p className='mt-2 leading-6 text-base'>Ziel dieser Dienste ist es, Unternehmen dabei zu helfen, ihre Online-Präsenz zu steigern, mehr Kunden zu gewinnen und ihre digitalen Marketingziele zu erreichen.</p>
            <ul className='leading-7 list-disc px-4 mt-1'>
                <li>Inhaltsvermarktung</li>
                <li>Social-Media-Marketing</li>
                <li>E-Mail Marketing</li>
                <li>Mobiles Marketing</li>
                <li>Videomarketing</li>
                <li>Pay-per-Click-Werbung (PPC)</li>
            </ul>
            <div className='mt-6'>
                <Link  onClick={scrollToTop} to='/online'><button type="submit" className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded">Einzelheiten</button></Link>
            </div>
            </div>
            <div className='overlap-5 mt-6 lg:px-14 lg:mr-1 xl:px-1'>
            <img className="floating" src="./online.jpg" alt="" />
            {/* <img className='image4' src="./online2.jpg" alt="" /> */}
            </div>
        </div>
    </motion.section>
  )
}

export default OurServicesDigital
