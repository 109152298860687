import { motion, useScroll, useTransform } from 'framer-motion'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

const Freelancer = () => {
  const ref = useRef(null)
  const {scrollYProgress} = useScroll ({
    target:ref,
    offset:["0 1", "1.33 1"],
  })

  const y = useTransform(scrollYProgress,[0,1],[0.8, 1])
  const x = useTransform(scrollYProgress,[0,1],[0.6, 1])
  return (
    <motion.section ref={ref} style={{ scale:y,opacity:x,}} className='px-16 lg:px-40  bg-blue-50' id='Freelancer'>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2'>
        <div className='overlap-5 mt-2 sm:mt-10 sm:right-32 lg:right-64 lg:px-12 xl:px-6 xl:right-40 sm:ml-20'>
          <img className="floating" src="./freelancer.jpg" alt="" />
          {/* <img className='crm2' src="./crm2.jpg" alt="" /> */}
        </div>
        <div className="mt-10 sm:mt-0 lg:pl-12">
            <p className='text-[#013379] mt-6 text-xl sm:text-[26px] font-bold leading-9'>FREIBERUFLER</p>
            <p className='mt-2 leading-6 text-base'>Um den Wert von Digihub Solutions UG für Unternehmen in Baden-Württemberg (BW) zu kommunizieren, die erstklassige freiberufliche Talente suchen, ist es wichtig, eine prägnante und ansprechende Botschaft zu entwickeln, die die Dienstleistungen hervorhebt und auf die Bedürfnisse und Herausforderungen der Zielgruppe eingeht.</p>
            <ul className='leading-7 list-disc px-4 mt-1'>
                <li>Stärken Sie Ihr Unternehmen jetzt</li>
                <li>Projektbasierte freiberufliche Unterstützung</li>
                <li>Langfristige freiberufliche Partnerschaften</li>
                <li>Spezialisierte freiberufliche Expertise</li>
            </ul>
            <div className='mt-6'>
                <Link   onClick={scrollToTop}  to='/freelancer'><button className="bg-[#1aa97e] hover:bg-[#2bdca7] text-white px-4 py-1 rounded">Einzelheiten</button></Link>
            </div>
        </div>
        </div>
    </motion.section>
  )
}

export default Freelancer
