import React, { useRef } from 'react'
import { motion, useInView } from 'framer-motion';

const fadeRightVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };

const ItAppDetails = () => {
  return (
    <motion.section
    variants={fadeRightVariants}
    initial="initial"
    animate="animate">
        <div className='px-16 lg:px-40'>
            <p className='text-[#013379] text-2xl sm:text-[26px] font-bold mt-2 py-2 leading-7'>IT-ANWENDUNG UND -ENTWICKLUNG</p>
        </div>
        <div className='text-sm lg:text-base px-8 md:px-20 lg:px-40 py-4'>
            <p className=''>Um das Wertversprechen von Digihub Solutions UG effektiv zu</p>
            <p className='mt-1 leading-6'>kommunizieren, ist es wichtig, eine Botschaft zu formulieren, die die Stärken und Vorteile der Dienstleistungen klar und ansprechend darstellt. Hier ist ein strukturierter Ansatz, um die Botschaft an potenzielle Kunden zu übermitteln:</p>
            <p className='mt-2'>Einführung in Digihub Solutions UG</p>
            <p className='mt-2 font-semibold text-gray-800'>Wer wir sind:</p>
            <p className='mt-2 leading-6'>Digihub Solutions UG ist ein führender Anbieter von IT-Dienstleistungen mit dem Ziel, Unternehmen durch maßgeschneiderte technologische Lösungen zu unterstützen, um ihre Effizienz zu steigern und Wachstum zu fördern.</p>
            <p className='mt-4 text-xl lg:text-[22px] font-bold text-[#013379]'>Unsere Dienstleistungen</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-700'>1. Bewerbungsmanagement:</p>
            <ul className='leading-7 ml-8 lg:ml-0 lg:px-12 list-disc mt-1'>
                <li>Wir bieten eine proaktive Überwachung und Wartung Ihrer Anwendungen, um kontinuierliche Effizienz und zuverlässige Performance sicherzustellen.</li>
                <li>Unsere Experten halten Ihre Software durch regelmäßige Updates und Patches sicher und aktuell.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold  text-gray-700'>2. Softwareentwicklung:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Unsere maßgeschneiderten Softwarelösungen sind speziell auf Ihre Geschäftsanforderungen abgestimmt, um maximale Geschäftswerte zu erzielen.</li>
                <li>Wir verwenden agile Entwicklungspraktiken, um Flexibilität zu gewährleisten und Lieferzeiten sowie Budgets einzuhalten.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold  text-gray-700'>3. Technischer Support:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Unser 24/7-Support-Service bietet schnelle Lösungen für technische Probleme, um Geschäftskontinuität und minimale Ausfallzeiten zu gewährleisten.</li>
                <li>Sie erhalten Zugang zu einem Team von IT-Experten, die bereit sind, Ihnen bei jeder Herausforderung zu helfen.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Unser Erfahrungsschatz</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-2'>
                <li><span className='font-semibold text-gray-800'>Qualifizierte Fachkräfte:</span> Wir sind stolz auf unser Team aus erfahrenen IT-Profis, die Technologie und Geschäftsprozesse verstehen.</li>
                <li><span className='font-semibold text-gray-800'>Kundenzentrierter Ansatz:</span> Wir nehmen uns Zeit, Ihre individuellen Herausforderungen zu verstehen, um gezielte Lösungen zu bieten, die wirklich einen Unterschied machen.</li>
                <li><span className='font-semibold text-gray-800'>Innovative Lösungen:</span> Wir bleiben an der Spitze der Technologieentwicklung, um Ihnen fortschrittliche und effektive IT-Dienstleistungen zu bieten.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Warum Digihub Solutions UG?</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-2'>
                <li><span className='font-semibold text-gray-800'>Strategische IT-Optimierung:</span> Wir sind Partner bei der Ausrichtung Ihrer IT-Infrastruktur an Ihren Geschäftszielen, um nachhaltige Effizienzsteigerungen zu erzielen.</li>
                <li><span className='font-semibold text-gray-800'>Skalierbare Services:</span> Unsere Dienstleistungen wachsen mit Ihrem Unternehmen mit und passen sich Ihren sich wandelnden Bedürfnissen an.</li>
                <li><span className='font-semibold text-gray-800'>Zuverlässiger Support:</span> Unser Engagement für exzellenten Service garantiert Ihnen jederzeit den Support, den Sie benötigen. Ideale Kunden.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Ideale Kunden</p>
            <ul className='leading-7 list-disc ml-8  lg:ml-0 lg:px-12 mt-2'>
                <li><span className='font-semibold text-gray-800'>Unternehmen jeder Größe:</span> Unsere IT-Lösungen sind flexibel und skalierbar, passend für Start-ups sowie etablierte multinationale Konzerne.</li>
                <li><span className='font-semibold text-gray-800'>Branchenübergreifend:</span> Wir verfügen über umfassende Erfahrungen in der Betreuung von Branchen mit komplexen IT-Anforderungen.</li>
                <li><span className='font-semibold text-gray-800'>Innovationsführer:</span> Organisationen, die nach innovativen IT-Lösungen suchen, werden in Digihub Solutions UG einen zuverlässigen Partner finden.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Aufruf zum Handeln</p>
            <p className='mt-2 leading-7'>Wenn Sie bereit sind, Ihre IT-Herausforderungen zu überwinden und Ihr Geschäft auf die nächste Stufe zu heben, kontaktieren Sie uns noch heute, um eine maßgeschneiderte Beratung zu beginnen.</p>
            <p className='mt-2 leading-6 mb-4'>Indem Sie diese strukturierte Botschaft verwenden, können Sie sicherstellen, dass potenzielle Kunden ein klares Verständnis davon haben, wie Digihub Solutions UG ihnen helfen kann, ihre geschäftlichen Herausforderungen zu bewältigen und wie sie von einer Partnerschaft profitieren können.</p>
        </div>
    </motion.section>
  )
}

export default ItAppDetails
