import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CookieConsent from "react-cookie-consent";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookieConsent style={{background:"#EDEEF0", color:"black"}}
      location="bottom"
      buttonText="I ACCEPT"
      declineButtonText="I DECLINE"
      declineButtonStyle={{
        padding:'8px 16px',
        borderRadius:'4px',
        backgrounColor:'#d5d7da',
        outline:'none',
        border:'none',
        color:'white',
        fontSize:'14px',
        fontWeight:'500'
      }}
      cookieName="myAwesomeCookieName2"
      buttonStyle={{ 
        padding:'8px 16px',
        borderRadius:'4px',
        backgroundImage:'linear-gradient(68deg, #72be44, #00a0fd)',
        outline:'none',
        color:'white',
        fontSize:'14px',
        fontWeight:'500',
        border:'1px solid transparent',
        borderImage:'linear-gradient(89deg, #72be44, #00a0fd)',
        borderImageSlice:'1',
      }}
      expires={150}
      enableDeclineButton
    >
      <p style={{ paddingLeft: "36px" }}>This website uses cookies to ensure you get the best experience on our website. By clicking "I ACCEPT" or continuing to browse the site, you agree to our use of cookies as described in our Cookie Policy.</p>
    </CookieConsent>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
