import React from 'react'
import { motion } from 'framer-motion';
const fadeRightVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };
const DigitalDetails = () => {
  return (
    <motion.section
    variants={fadeRightVariants}
    initial="initial"
    animate="animate">
        <div className='px-16 lg:px-40'>
            <p className='text-[#013379] text-2xl sm:text-[26px] font-bold mt-2 py-2 leading-7'>ONLINE MARKETING</p>
        </div>
        <div className='text-sm lg:text-base px-8 md:px-20 lg:px-40 py-2'>
            <p className=''>Übersicht über Digihub Solutions UG - Online-Marketing</p>
            <p className='mt-2 font-semibold text-gray-800'>Wer wir sind:</p>
            <p className='mt-2 leading-6'>Digihub Solutions UG ist ein spezialisierter Anbieter von Online-Marketing- Dienstleistungen, der Unternehmen dabei unterstützt, sich in der digitalen Landschaft zu positionieren. Unser Ziel ist es, Marken durch strategische Online-Marketingkampagnen mit ihrer Zielgruppe zu verbinden und greifbare Ergebnisse zu erzielen.</p>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Kerndienstleistungen im Online-Marketing:</p>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>1. Suchmaschinenoptimierung (SEO):</p>
            <ul className='leading-7 ml-8 lg:ml-0 lg:px-12 list-disc mt-1'>
                <li>Sichtbarkeit der Website in Suchmaschinenergebnissen verbessern, um organischen Traffic anzuziehen.</li>
                <li>Keyword-Recherche und Inhaltsoptimierung zur Steigerung des Suchrankings.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>2. Pay-per-Click-Werbung (PPC):</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Verwaltung von PPC-Kampagnen auf Plattformen wie Google Ads und Bing Ads für sofortige Sichtbarkeit.</li>
                <li>Konversion-Rate-Optimierung zur Maximierung der Kapitalrendite.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>3. Sozial-Media-Marketing:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Erstellung ansprechender Inhalte und Kampagnen für Plattformen wie Facebook, Instagram, LinkedIn und Twitter.</li>
                <li>Verwaltung von Sozial-Media-Anzeigen zur gezielten Ansprache bestimmter demografischer Zielgruppen.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>4. Content-Marketing:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Entwicklung einer Content-Strategie, die mit der Markenbotschaft und den Interessen des Publikums übereinstimmt.</li>
                <li>Erstellung hochwertiger Blogbeiträge, Videos, Infografiken und anderer Inhalte zur Ansprache und Information des Publikums.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>5. E-Mail-Marketing:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Erstellung maßgeschneiderter E-Mail-Kampagnen zur Lead-Pflege und Kundenbindung.</li>
                <li>Analyse der Kampagnenleistung zur kontinuierlichen Verbesserung.</li>
            </ul>
            <p className='mt-2 text-lg lg:text-xl font-semibold text-gray-800'>6. Webanalyse und Reporting:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li>Überwachung digitaler Marketingbemühungen mithilfe von Tools wie Google Analytics.</li>
                <li>Bereitstellung von umsetzbaren Erkenntnissen durch regelmäßige Berichte und Analysen.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Warum mit Digihub Solutions UG zusammenarbeiten?</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1'>
                <li><span className='font-semibold text-gray-800'>Datengesteuerte Strategien:</span> Nutzung von Daten und Analysen zur Unterstützung zielgerichteter und effektiver Kampagnen.</li>
                <li><span className='font-semibold text-gray-800'>Maßgeschneiderte Lösungen:</span> Individuelle Marketingpläne, abgestimmt auf spezifische Ziele und Budgets.</li>
                <li><span className='font-semibold text-gray-800'>Expertenteam:</span> Erfahrenes Team von Online-Marketing-Experten für kreative und innovative Lösungen.</li>
                <li><span className='font-semibold text-gray-800'>Transparente Kommunikation:</span> Klarer Austausch und regelmäßige Updates über den Kampagnenfortschritt.</li>
            </ul>
            <p className='mt-2 text-xl lg:text-[22px] font-bold text-[#013379]'>Ideale Kundschaft:</p>
            <ul className='leading-7 list-disc ml-8 lg:ml-0 lg:px-12 mt-1 mb-4'>
                <li>Unternehmen, die Online-Wachstum anstreben.</li>
                <li>Unternehmen, die fachkundige Beratung benötigen.</li>
                <li>Organisationen mit spezifischen Zielgruppen.</li>
            </ul>
        </div>
    </motion.section>
  )
}

export default DigitalDetails
